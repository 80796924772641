import React, { useState } from "react";
import ReactDOM from "react-dom";
import ReactCountryFlag from "react-country-flag"

const Counter = ({ value, index, countryCode, onDecrement, onIncrement, useFlags, hideInput }) => {

    return (
        <div className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 mt-3 sm:mt-4 md:mt-3 md:mb-2 flex items-center justify-center flex-wrap flex-row self-start">

            <button className={"tracker-btn flex items-center border border-black rounded-3xl p-3 md:px-4 shadow-xl bg-slate-800 hover:bg-slate-700 " + (useFlags && value === 0 ? "bg-gray-800 grayscale-[80%] hover:bg-gray-800" : "")}
                onClick={() => {
                    onDecrement();
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    onIncrement();
                }}
            >
                {
                    useFlags ? (
                        <ReactCountryFlag
                            className="!w-[60px] xs:!w-[68px] sm:!w-[80px] xl:!w-[92px] 2xl:!w-[112px]"
                            countryCode={countryCode}
                            svg
                            style={{
                                width: '68px',
                                height: 'auto',
                                display: 'inline-block',
                                marginRight: '24px'
                            }}
                            title={countryCode}
                        />
                    ) : (
                        <div className={"border border-black pt-1 text-4xl md:text-5xl bg-blue-500 w-[68px] sm:w-[80px] xl:w-[92px] 2xl:w-[112px] max-w-full h-[51px] sm:h-[60px] xl:h-[69px] 2xl:h-[84px] mr-6 " + (value === 0 ? "bg-zinc-800" : "")}>
                            <div className="flex justify-center items-center h-full font-russo">{index + 1}</div>
                        </div>
                    )
                }
                <span className={"text-4xl md:text-5xl inline-block mr-2 " + (value === 0 ? "text-gray-600" : "")}>{value}</span>
            </button>
            {!hideInput &&
                <input name="flags" className="hidden lg:block bg-transparent border-b mt-1 border-black max-w-[180px] xl:max-w-[200px] text-gray-400" type="text" defaultValue={index + 1 + " "} />
            }
        </div>
    );
};

function countNonEmpty(array) {
    return array.filter(Boolean).length;
}

const countryCodeList = ["CA", "PL", "FI", "DK", "BR", "DE", "FR", "GB", "SE", "NO", "ES", "AT", "TR", "US", "PT", "AR", "KR", "JP", "AU", "CN", "HR", "GR", "UA"];

function getFlagCode(index) {
    return countryCodeList[index]
}

function setInitialCounters(numTeams) {
    const initialCounters = []

    for (var i = 0; i < numTeams; i++) {
        initialCounters.push(4)
    }
    return initialCounters;
}
const Counters = () => {
    const [numTeams, setNumTeams] = useState(16);
    const initialCounters = setInitialCounters(numTeams);

    const [counters, setCounters] = useState(initialCounters);
    const [useFlag, setFlag] = useState(true);
    const [currentIndex, changedIndex] = useState(1);

    const [hideInput, setHiddenInput] = useState(false);

    const [numUkwPlayers, setNumUkwPlayers] = useState(0);

    const sum = counters.slice(currentIndex - 1).reduce((acc, item) => acc + item, 0);
    const aliveTeams = countNonEmpty(counters.slice(currentIndex - 1));

    var alivePlayers = sum;
    if (alivePlayers - numUkwPlayers > -1) {
        alivePlayers = alivePlayers - numUkwPlayers;
    }
    else {
        alivePlayers = 0;
    }
    const [showBar, setShowBar] = useState(true)

    function changeIndex(input) {
        if (input.target.value > 0) {
            changedIndex(input.target.value);
            if (input.target.value > currentIndex) {
                var increasedTeams = input.target.value - currentIndex;
                for (var i = 0; i < increasedTeams; i++) {
                    setCounters((counters) => ([
                        ...counters,
                        4,
                    ]));
                }
                setNumTeams(numTeams + increasedTeams);
            }
            else if (input.target.value < currentIndex) {
                var decreasedTeams = input.target.value - currentIndex;
                var difference = currentIndex - input.target.value;
                setCounters(counters.slice(0, decreasedTeams));
                setNumTeams(numTeams - difference);
            }
        }
    }

    function hideBar() {
        if (showBar) {
            setShowBar(false);
        }
        else {
            setShowBar(true);
        }
    }

    function IncreaseUkwPlayer() {
        console.log(alivePlayers, numUkwPlayers);
        if (numUkwPlayers < 9 && alivePlayers > 0) {
            setNumUkwPlayers(numUkwPlayers + 1);
        }
    }
    function DecreaseUkwPlayer() {
        console.log(alivePlayers, numUkwPlayers);
        if (numUkwPlayers > 0) {
            setNumUkwPlayers(numUkwPlayers - 1);
        }
    }
    return (
        <div>
            <div className="md:mb-2 border-b border-black">
                <div className="container mx-auto">
                    <div className="flex flex-row flex-wrap justify-center mt-2 mb-2">
                        <p className="mx-4 border border-gray-400"><span className="bg-orange-700 text-white xs:text-2xl md:text-4xl p-2 inline-block">{alivePlayers}</span><span className="xs:text-2xl md:text-4xl py-2 px-3 inline-block">ALIVE</span></p>
                        <p className="mx-4 border border-gray-400"><span className="bg-lime-700 text-white xs:text-2xl md:text-4xl p-2 inline-block">{aliveTeams}</span><span className="xs:text-2xl md:text-4xl py-2 px-3 inline-block">TEAMS</span></p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto mb-10 lg:mb-28">
                <div className="flex flex-wrap flex-row px-4">
                    {counters.map((value, index) => {

                        if (index + 1 >= currentIndex) {
                            return (
                                <Counter
                                    value={value}
                                    index={index}
                                    key={"counter-" + index}
                                    countryCode={getFlagCode(index)}
                                    useFlags={useFlag}
                                    hideInput={hideInput}
                                    onDecrement={() => {
                                        const countersCopy = [...counters];
                                        if (countersCopy[index] > 0) {
                                            countersCopy[index] -= 1;
                                            setCounters(countersCopy);
                                        }
                                    }}
                                    onIncrement={() => {
                                        const countersCopy = [...counters];
                                        if (countersCopy[index] < 4) {
                                            countersCopy[index] += 1;
                                            setCounters(countersCopy);
                                        }
                                    }}
                                />
                            )
                        }
                    })
                    }
                    <div className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 mt-3 sm:mt-4 md:mt-3 md:mb-2 flex items-center justify-center flex-wrap flex-row">
                        <div className="unknown-btn flex items-center border border-black rounded-3xl p-3 xs:px-4 sm:px-3 md:px-4 2xl:px-4 shadow-xl h-[71px] xs:h-[77px] sm:h-[86px] xl:h-[95px] 2xl:h-[110px]">
                            <button className="px-2 bg-red-700 hover:bg-red-600 text-2xl md:text-4xl text-center min-w-[30px] h-[30px] leading-[30px] sm:min-w-[40px] sm:h-[40px] sm:leading-[40px] 2xl:min-w-[50px] 2xl:h-[50px] 2xl:leading-[50px]"
                                onClick={() => {
                                    DecreaseUkwPlayer();
                                }}
                            >&#x2212;</button>
                            <span className="text-4xl md:text-5xl inline-block mx-4 xl:mx-6">{numUkwPlayers}</span>
                            <button className="px-2 bg-lime-700 hover:bg-lime-600 text-2xl md:text-4xl text-center min-w-[30px] h-[30px] leading-[30px] sm:min-w-[40px] sm:h-[40px] sm:leading-[40px] 2xl:min-w-[50px] 2xl:h-[50px] 2xl:leading-[50px]"
                                onClick={() => {
                                    IncreaseUkwPlayer();
                                }}
                            >&#x2b;</button>
                        </div>
                        <span className="pt-2 text-lg xl:text-xl text-gray-400 w-full text-center">Unknown death</span>
                    </div>
                </div>
            </div>
            <div className="lg:fixed lg:bottom-0 w-full bg-zinc-900 pt-3 sm:pt-0 pb-1 sm:pb-0">
                <div className="container mx-auto relative">
                    <div className={"flex flex-row flex-wrap text-sm md:text-lg justify-center items-center px-4 " + (showBar ? "" : "hidden")}>
                        <a className="text-2xl font-russo mr-4 w-full sm:w-auto text-center" href="https://pubgenie.gg/" target="_blank">PUBGenie</a>
                        {removeTeam(setCounters, counters, setNumTeams, numTeams)}

                        {addTeam(setCounters, counters, setNumTeams, numTeams)}

                        <div className="my-2 w-full text-center sm:w-auto">
                            {startTeamNr(changeIndex)}
                        </div>


                        {iconType(useFlag, setFlag)}

                        {inputToggle(hideInput, setHiddenInput)}

                        {resetView(setCounters, initialCounters, setNumUkwPlayers)}
                    </div>

                    <button className="hidden lg:block absolute top-[-40px] left-1/2 right-1/2 -translate-x-1/2 500 text-white p-2 inline-block bg-slate-700 hover:bg-slate-800 w-[40px] h-[40px] flex items-center justify-center"
                        onClick={() => {
                            hideBar();
                        }}>
                        <span>&#8616;</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Counters;

function resetView(setCounters, initialCounters, setNumUkwPlayers) {
    return <button className="border text-white p-2 inline-block mx-1 md:mx-2 my-2 bg-red-700 hover:bg-red-600"
        onClick={() => {
            setCounters(initialCounters);
            setNumUkwPlayers(0);
        }}
    >
        Reset
    </button>;
}

function iconType(useFlag, setFlag) {
    return <button className="border text-white p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800"
        onClick={() => {
            if (!useFlag) {
                setFlag(true);
            }
            else {
                setFlag(false);
            }
        }}
    >
        {useFlag ? "Use numbers" : "Use flags"}
    </button>;
}

function inputToggle(hideInput, setHiddenInput) {
    return <button className="hidden lg:block border text-white p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800"
        onClick={() => {
            if (!hideInput) {
                setHiddenInput(true);
            }
            else {
                setHiddenInput(false);
            }
        }}
    >
        <span className="pr-1">&#9998;</span>
        {hideInput ? "Show input" : "Hide input"}
    </button>;
}

function startTeamNr(changeIndex) {
    return <>
        <span className="ml-2 mr-2">Start on team nr:</span>
        <input type="number" defaultValue="1" onChange={changeIndex} className="h-full pl-2 w-[46px] bg-transparent" />
    </>;
}

function removeTeam(setCounters, counters, setNumTeams, numTeams) {
    return <button className="border text-white mx-1 md:mx-2 uppercase flex items-center my-2 group h-[42px]"
        onClick={() => {
            setCounters(counters.slice(0, -1));
            setNumTeams(numTeams - 1);
        }}
    >
        <div className="h-full px-2 bg-red-700 group-hover:bg-red-600 border-r text-2xl md:text-4xl min-w-[40px] leading-[40px]">&#x2212;</div>
        <span className="p-2">team</span>
    </button>;
}

function addTeam(setCounters, counters, setNumTeams, numTeams) {
    return <button className="border text-white mx-1 md:mx-2 uppercase flex items-center my-2 group h-[42px]"
        onClick={() => {
            setCounters([...counters, 4]);
            setNumTeams(numTeams + 1);
        }}
    >
        <div className="h-full px-2 bg-lime-700 group-hover:bg-lime-600 border-r text-2xl md:text-4xl min-w-[40px] leading-[40px]">&#x2b;</div>
        <span className="p-2">team</span>
    </button>;
}
